import { NavigateFunction } from "react-router-dom"

const en = {
    __LANGUAGE: "en",
    ACCEDI: "Sign in",
    LOGIN: 'Login',
    RICORDAMI: 'Remember me',
    CREDENZIALI_NON_RICONOSCIUTE: 'Invalid credentials',
    PASSWORD_DIMENTICATA: "Forgot password?",
    CERCA: 'Search',
    CAMBIA_PASSWORD: "Change password",
    SOTTOUTENTI: "Subusers",
    BILLING: "Billing",
    MODULI: "Modules",
    LOGOUT: "Logout",
    COPIATO: "Copiato!",
    OK: "Ok",
    MODIFICA: "Modifica",
    ELIMINA: "Elimina",
    VISUALIZZA: "Visualizza",
    ORDINA: "Ordina",
    AGGIUNGI: "Add",
    CARICA: "Upload",
    ANNULLA: "Cancel",
    RIFIUTA: "Deny",
    RIPRISTINA: "Restore",
    RIGENERA: "Regenerate",
    SELEZIONA_TUTTI: "check all",
    DESELEZIONA_TUTTI: "Uncheck all",
    CONFERMA: "Confirm",
    INVIA: "Send",
    ATTENDERE: "Please wait...",
    ATTENZIONE: "Warning",
    ERRORE_GENERICO: "Si è verificato un errore, si prega di riprovare.",
    SELEZIONA_UN_VALORE: "Seleziona un valore",
    SELEZIONA_UN_FILE: "Seleziona un file",
    SI: "Sì",
    NO: "No",
    DA: "Da",
    TUTTI: "Tutti",
    SALES_ORDER: "Ordine di vendita",
    TRANSFER_ORDER:"Ordine di trasferimento",
    SALVA: "Salva",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Eliminazione in corso...",
    SALVATAGGIO_IN_CORSO: "Salvataggio in corso...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operazione avvenuta con successo",
    COPIA_VALORE: "Copia valore",
    PROCEDI: "Procedi",
    PROSEGUI: "Prosegui",
    CAMBIA: "Cambia",
    CREA: "Crea",
    ISTRUZIONI: "Instructions",
    DETTAGLI: "Details",
    MOSTRA: "Show",
    RISULTATI: "__COUNT__ results",
    HOME: "Home",
    INIZIA: "Inizia",
    VERIFICA: "Check",
    SELEZIONA_FILE: "Seleziona file",
    SALE_CHANNEL:"Sale Channel",
    SALES_CHANNEL:"Sale Channels",
    BADGE_SHOP_PRIMARY:"Primary Shop",
    ARTICOLO: "Article",
    QUANTITA: "Quantity",
    PREZZO_UNITARIO: "Unit price",
    PREZZO_TOTALE: "Total price",
    SCONTO: "Discount",
    MARKUP: "Markup",
    TOTALE_ORDINE: "Total order",
    SUBTOTALE: "Subtotale",
    SPESE_DI_SPEDIZIONE: "Spese di spedizione",
    VAI_A_DETTAGLIO: "Goto details",
    APRI_ANTEPRIMA: "Show preview",
    GOTO_CHECKOUT: "Go to checkout",
    LISTINO: "Pricebook",
    EMPTY: "Empty",
    CLOSE:"Close",
    CALENDAR:{
        LAST_3M:"Last 3 months",
        LAST_6M:"Last 6 months",
        THIS_Y: "Current year",
        LAST_Y: "Last year",
        LAST_2Y: "Last 2 years",
    },
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Phone",
        MOBILE: "Mobile"
    },
    MENU: {
        CATALOGO: "Catalogo",
        ORDINI: "Ordini",
        ORDINE: "Ordine",
        PROFILO: "Profilo",
        LOGOUT: "Logout",
        RICERCA_AVANZATA: "Ricerca avanzata",
        DETTAGLIO_ARTICOLO: "Dettaglio articolo",
    },
    FORMS: {
        ERRORI_NEL_FORM: "Ci sono errori nei campi del form, si prega di controllare.",
        CAMPO_OBBLIGATORIO: "Campo obbligatorio",
        LUNGHEZZA_COMPRESA: "La lunghezza del campo deve essere compresa tra __MIN__ e __MAX__ caratteri.",
        VALORE_COMPRESO: "Il valore del campo deve essere compreso tra __MIN__ e __MAX__."
    },
    REACT_SELECT: {
        PLACEHOLDER: "Seleziona un valore...",
        NESSUN_RISULTATO: "Nessun risultato trovato."
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "Nuova configurazione"
    },
    PAGES: {
        LOGIN: {
            ACCEDI: "Accedi",
            LOGIN: 'Login',
            RICORDAMI: 'Ricordami',
            CREDENZIALI_NON_RICONOSCIUTE: 'Credenziali non riconosciute',
            PASSWORD_DIMENTICATA: "Password dimenticata?",
            PASSWORD_DIMENTICATA_DESC: "Inserisci la tua email per resettare la tua password.",
            PASSWORD_DIMENTICATA_SUCCESS: "Ti abbiamo inviato una email per reimpostare la tua password. Segui le istruzioni e riprova ad accedere con la nuova password.",
            TORNA_ALLA_LOGIN: "Torna alla login",
        },
        CATALOGO: {
            NESSUN_ARTICOLO_DISPONIBILE: "Nessun articolo corrisponde ai filtri selezionati.",
            ACQUISTA: "Acquista",
            TAGLIA: "Taglia",
            NESSUNA: "Nessuna",
            TUTTE: "Tutte",
            CERCA_ARTICOLO: "Cerca articolo",
            MINIMO_TRE_CARATTERI: "Inserisci almeno tre caratteri.",
            NESSUN_RISULTATO_VAI_A_RICERCA_AVANZATA: "Nessun risultato trovato. Vai alla ricerca avanzata",
            NESSUN_RISULTATO: "Nessun risultato trovato.",
            FILTERS: {
                SELEZIONA_TUTTI: "Seleziona tutti",
                BRAND: "Brand",
                SEASON: "Stagioni",
                SIZE: "Taglie",
                CATEGORIES: "Categorie",
                IMAGES: "Immagini",
                NESSUN_FILTRO: "Nessun filtro",
                WITH_IMAGES: "Con immagini",
                WITHOUT_IMAGES: "Senza immagini",
                FTA: "Fta",
                CARRYOVER: "Carry over",
                COLORS: "Colori",
                RESETTA_FILTRI: "Resetta filtri",
                ORDINAMENTO: {
                    SKU_PARENT_ASC: "Codice articolo (A-Z)",
                    SKU_PARENT_DESC: "Codice articolo (Z-A)",
                    LAST_INFO_UPDATE_ASC: "Modifica più recente",
                    LAST_INFO_UPDATE_DESC: "Modifica meno recente",
                }
            }
        },
        ORDERS: {
            ARTICOLI: "Articles",
            SCARICA_ORDINI: "Download Orders",
            ORDINE: "Order",
            STATO_ORDINE: "Status",
            DATA: "Date",
            CONTROVALORE: "Total",
            NUMERO_ARTICOLI: "item number",
            APRI_ANTEPRIMA: "Show preview",
            VAI_A_DETTAGLIO: "Go to details",
            COPIA_ID: "Copy ID",
            COPIA_CODICE_ORDINE: "Copy order code",
            INFORMAZIONI_ACQUIRENTE: "Buyer info",
            SPEDIZIONE: "Shipping",
            FATTURAZIONE: "Billing",
            ORDINI_EFFETTUATI: "__COUNT__ Orders placed",
            STATUS_CHANGE: "Change order status",
            ORDER_NUMBER: "Order number",
            SPEDITO_A:"Shipping to",
            FROM_SENT_TO:"To be sent to",
            SENT_TO:"Spedire a",
            STATUS: {
                PENDING: "Aperto",
                CONFIRMED: "Confermato",
                CANCELED: "Cancellato",
                REFURBISHED: "Rinnovato",
                SHIPPED: "Spedito",
                PARTIALLY_SHIPPED: "Parzialmente spedito",
                OTHER: "Altro",
            }
        },
        ORDER:{
            CURRENT_ORDER_TITLE:"Your current order",
            NO_ORDER1: "You order is empty. Take a look at the",
            NO_ORDER2:"and find something that's right for you!",
            OUR_CATALOG:"Our catalog",
            ORDINE_NUMERO: "Order N°",
            DATA: "Data",
            QUANTITA_NELL_ORDINE: "Quantità nell'ordine",
            TOTALE: "Totale",
            SVUOTA: "Svuota",
            VAI_A_CHECKOUT: "Vai al checkout",
            VARIAZIONE_TEXT: "Articolo nell'ordine ha subito una variazione del prezzo a te dedicato. Puoi verificare le condizioni aggiornate nell'elenco qui sotto.",
            VARIAZIONI_TEXT: "Articoli nell'ordine hanno subito una variazione del prezzo a te dedicato. Puoi verificare le condizioni aggiornate nell'elenco qui sotto.",
            NO_ITEMS_IN_ORDER_CURRENT: (navigate: NavigateFunction) => <div>Il tuo ordine è vuoto. Dai un'occhiata al <span className="cs-link" onClick={(()=>navigate("/catalog"))}>nostro catalogo</span> e trova qualcosa che fa per te!</div>,
            NO_ITEMS_IN_ORDER: "L'ordine è vuoto.",
            ELENCO_ARTICOLI_ORDINE: "Elenco articoli nell'ordine",
            INFORMAZIONI_NON_DISPONIBILI: "Informazioni non disponibili.",
            TAGLIA: "Taglia",
            TAGLIE: "Taglie",
            STAGIONE: "Stagione",
            PREZZO_UNITARIO: "Prezzo unitario",
            QUANTITA: "Quantità",
            INDIRIZZO_DI_SPEDIZIONE: "Indirizzo di spedizione",
            NESSUN_INDIRIZZO_SPEDIZIONE: "Nessun indirizzo di spedizione indicato per quest'ordine.",
            SCEGLI_UN_INDIRIZZO: "Scegli un indirizzo",
            ORDINE_VERRA_SPEDITO_A: "Quest'ordine verrà spedito a",
            ORDINE_SPEDITO_A: "Quest'ordine è stato spedito a",
            PREZZO_CORRENTE_AGGIORNATO: "Prezzo corrente aggiornato",
            CONFERMA_RIMOZIONE_ARTICOLO: (sku: string)=> <p>Sei sicuro di voler rimuovere l'articolo <strong>{sku}</strong> dall'ordine?</p>,
            CONFERMA_SVUOTAMENTO_ORDINE: "Sei sicuro di voler svuotare l'ordine?",
            INDIRIZZO_ATTUALE: "Indirizzo attuale",
            NUOVO_INDIRIZZO: "Inserisci un nuovo indirizzo",
            SELEZIONA_UN_ALTRO_INDIRIZZO: "Seleziona un altro indirizzo",
            ACQUISTA_ALTRE_TAGLIE: "Acquista altre taglie",
        },
        ITEM: {
            MAGGIORI_INFORMAZIONI: "Maggiori informazioni",
            VARIAZIONI_DISPONIBILI: "Variazioni disponibili",
            NASCONDI_ESAURITE: "Nascondi esaurite",
            MORE_INFO: {
                BRAND: "Brand",
                COLOR: "Colore",
                COMPOSITION: "Composizione",
                DESCRIZIONE: "Descrizione",
                HS_CODE: "HS Code",
                SEASON: "Stagione",
                MADE_IN: "Made in",
                GENDER: "Gender",
                FIRST_CATEGORY: "First category",
                SECOND_CATEGORY: "Second category",
                SCALA_TAGLIE: "Scala taglie",
                TITOLO: "Titolo",
                DIMENSIONI: "Dimensioni",
                CARRY_OVER: "Carry Over",
            }
        }
    },
    ITEMS:{
        NO_ITEM_PRESENT: "There are no items in the order.",
        ITEM_NOT_PRESENT: "Item not present in the inventory.",
        ITEMS_ORDER: "Items in this order",
        SKU:"Sku",
        BRAND:"Brand",
        SEASON:"Season",
        TITLE:"Title",
        QT_ROW:"Order Line Quantity",
        TOT_ROW:"Total row cost",
        SIZE:"Size",
        COLOR:"Color",
        QTY:"Quantity",
        PRICE:"Price",
    },
    FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Select at least one filter to view results.",
        NESSUN_RISULTATO: "No results found.",
        ERRORE: "An error occurred, please try again."
    },

}

export default en