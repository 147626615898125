import { EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo } from "@csapi/provisioning/api"
import axios, { AxiosResponse } from "axios"
import { checkSessionExpired } from "./Utils"
import { endOfDay, startOfDay, subMonths } from "date-fns"
import format from "date-fns/format"
import { EntitySingleResponseOrder, Order } from "@csapi/shop"

const getCart = async ():Promise<Order|undefined> =>{
    try {
        const params = {
            pageIndex: 0, 
            pageSize: 1,
            statuses: "PENDING",
            sort: "date[ASC]",
            dateFrom: startOfDay(subMonths(new Date(), 120)).toISOString(),
            dateTo: endOfDay(new Date()).toISOString(),
        }
        const response:AxiosResponse<EntityListWithAdditionalInfoResponseOrderListOrderAdditionalInfo,any> = await axios.get('api/orders', {
            params
        })
        const orderList = response.data.content

        if(!orderList || orderList.length === 0) {
            return undefined
        } 

        return orderList[0]
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}

const saveCart = async (cart: Order):Promise<Order|undefined> =>{
    try{
        if(cart._id) {
            // update and return response
            const updateResponse:AxiosResponse<EntitySingleResponseOrder,any> = await axios.patch(`api/orders/${cart._id.$oid}`, cart)
            return updateResponse.data.content
        }

        // create and return response
        const createResponse:AxiosResponse<EntitySingleResponseOrder,any> = await axios.post(`api/orders`, cart)
        return createResponse.data.content
    } catch(error: any) {
        checkSessionExpired(error)
        throw error
    }
}

export { getCart, saveCart }
