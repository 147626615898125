import { NavigateFunction } from "react-router-dom"

const it = {
    __LANGUAGE: "it",
    CERCA: "Cerca",
    CAMBIA_PASSWORD: "Cambia password",
    SOTTOUTENTI: "Sottoutenti",
    BILLING: "Billing",
    MODULI: "Moduli",
    LOGOUT: "Logout",
    COPIATO: "Copiato!",
    OK: "Ok",
    MODIFICA: "Modifica",
    ELIMINA: "Elimina",
    VISUALIZZA: "Visualizza",
    ORDINA: "Ordina",
    AGGIUNGI: "Aggiungi",
    CARICA: "Carica",
    ANNULLA: "Annulla",
    RIFIUTA: "Rifuta",
    RIPRISTINA: "Ripristina",
    RIGENERA: "Rigenera",
    SELEZIONA_TUTTI: "Seleziona tutti",
    DESELEZIONA_TUTTI: "Deseleziona tutti",
    CONFERMA: "Conferma",
    INVIA: "Invia",
    ATTENDERE: "Attendere, prego",
    ATTENZIONE: "Attenzione",
    ERRORE_GENERICO: "Si è verificato un errore, si prega di riprovare.",
    SELEZIONA_UN_VALORE: "Seleziona un valore",
    SELEZIONA_UN_FILE: "Seleziona un file",
    SI: "Sì",
    NO: "No",
    DA: "Da",
    TUTTI: "Tutti",
    SALES_ORDER: "Ordine di vendita",
    TRANSFER_ORDER:"Ordine di trasferimento",
    SALVA: "Salva",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Eliminazione in corso...",
    SALVATAGGIO_IN_CORSO: "Salvataggio in corso...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operazione avvenuta con successo",
    COPIA_VALORE: "Copia valore",
    PROCEDI: "Procedi",
    PROSEGUI: "Prosegui",
    CAMBIA: "Cambia",
    CREA: "Crea",
    ISTRUZIONI: "Istruzioni",
    DETTAGLI: "Dettagli",
    MOSTRA: "Mostra",
    RISULTATI: "__COUNT__ risultati",
    HOME: "Home",
    INIZIA: "Inizia",
    VERIFICA: "Verifica",
    SELEZIONA_FILE: "Seleziona file",
    SALE_CHANNEL:"Canale di vendita",
    SALES_CHANNEL:"Canali di vendita",
    BADGE_SHOP_PRIMARY:"Shop Primario",
    ARTICOLO: "Articolo",
    QUANTITA: "Quantità",
    PREZZO_UNITARIO: "Prezzo unitario",
    PREZZO_TOTALE: "Prezzo totale",
    SCONTO: "Sconto",
    MARKUP: "Markup",
    TOTALE_ORDINE: "Totale ordine",
    SUBTOTALE: "Subtotale",
    SPESE_DI_SPEDIZIONE: "Spese di spedizione",
    VAI_A_DETTAGLIO: "Vai al dettaglio",
    APRI_ANTEPRIMA: "Apri anteprima",
    GOTO_CHECKOUT: "Vai al checkout",
    LISTINO: "Listino",
    EMPTY: "Svuota",
    CLOSE:"Chiudi",
    CALENDAR:{
        LAST_3M:"Ultimi 3 mesi",
        LAST_6M:"Ultimi 6 mesi",
        THIS_Y: "Anno corrente",
        LAST_Y: "Ultimo anno",
        LAST_2Y: "Ultimi 2 anni",
    },
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Telefono",
        MOBILE: "Cellulare"
    },
    MENU: {
        CATALOGO: "Catalogo",
        ORDINI: "Ordini",
        ORDINE: "Ordine",
        PROFILO: "Profilo",
        LOGOUT: "Logout",
        RICERCA_AVANZATA: "Ricerca avanzata",
        DETTAGLIO_ARTICOLO: "Dettaglio articolo",
    },
    FORMS: {
        ERRORI_NEL_FORM: "Ci sono errori nei campi del form, si prega di controllare.",
        CAMPO_OBBLIGATORIO: "Campo obbligatorio",
        LUNGHEZZA_COMPRESA: "La lunghezza del campo deve essere compresa tra __MIN__ e __MAX__ caratteri.",
        VALORE_COMPRESO: "Il valore del campo deve essere compreso tra __MIN__ e __MAX__."
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "Nuova configurazione"
    },
    REACT_SELECT: {
        PLACEHOLDER: "Seleziona un valore...",
        NESSUN_RISULTATO: "Nessun risultato trovato."
    },
    PAGES: {
        LOGIN: {
            ACCEDI: "Accedi",
            LOGIN: 'Login',
            RICORDAMI: 'Ricordami',
            CREDENZIALI_NON_RICONOSCIUTE: 'Credenziali non riconosciute',
            PASSWORD_DIMENTICATA: "Password dimenticata?",
            PASSWORD_DIMENTICATA_DESC: "Inserisci la tua email per resettare la tua password.",
            PASSWORD_DIMENTICATA_SUCCESS: "Ti abbiamo inviato una email per reimpostare la tua password. Segui le istruzioni e riprova ad accedere con la nuova password.",
            TORNA_ALLA_LOGIN: "Torna alla login",
        },
        CATALOGO: {
            NESSUN_ARTICOLO_DISPONIBILE: "Nessun articolo corrisponde ai filtri selezionati.",
            ACQUISTA: "Acquista",
            TAGLIA: "Taglia",
            NESSUNA: "Nessuna",
            TUTTE: "Tutte",
            CERCA_ARTICOLO: "Cerca articolo",
            MINIMO_TRE_CARATTERI: "Inserisci almeno tre caratteri.",
            NESSUN_RISULTATO_VAI_A_RICERCA_AVANZATA: "Nessun risultato trovato. Vai alla ricerca avanzata",
            NESSUN_RISULTATO: "Nessun risultato trovato.",
            FILTERS: {
                SELEZIONA_TUTTI: "Seleziona tutti",
                BRAND: "Brand",
                SEASON: "Stagioni",
                SIZE: "Taglie",
                CATEGORIES: "Categorie",
                IMAGES: "Immagini",
                NESSUN_FILTRO: "Nessun filtro",
                WITH_IMAGES: "Con immagini",
                WITHOUT_IMAGES: "Senza immagini",
                FTA: "Fta",
                CARRYOVER: "Carry over",
                COLORS: "Colori",
                RESETTA_FILTRI: "Resetta filtri",
                ORDINAMENTO: {
                    SKU_PARENT_ASC: "Codice articolo (A-Z)",
                    SKU_PARENT_DESC: "Codice articolo (Z-A)",
                    LAST_INFO_UPDATE_ASC: "Modifica più recente",
                    LAST_INFO_UPDATE_DESC: "Modifica meno recente",
                }
            }
        },
        ORDERS: {
            ARTICOLI: "Articoli",
            SCARICA_ORDINI: "Scarica ordini",
            ORDINE: "Ordine",
            STATO_ORDINE: "Stato",
            DATA: "Data",
            CONTROVALORE: "Totale",
            NUMERO_ARTICOLI: "Numero articoli",
            APRI_ANTEPRIMA: "Apri anteprima",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            COPIA_CODICE_ORDINE: "Copia codice ordine",
            INFORMAZIONI_ACQUIRENTE: "Informazioni acquirente",
            SPEDIZIONE: "Spedizione",
            FATTURAZIONE: "Fatturazione",
            ORDINI_EFFETTUATI: "__COUNT__ Ordini effettuati",
            STATUS_CHANGE: "Cambia stato dell'ordine",
            ORDER_NUMBER: "Numero ordine",
            SPEDITO_A:"Spedito a",
            FROM_SENT_TO:"Da spedire a",
            SENT_TO:"Spedire a",
            STATUS: {
                PENDING: "Aperto",
                CONFIRMED: "Confermato",
                CANCELED: "Cancellato",
                REFURBISHED: "Rinnovato",
                SHIPPED: "Spedito",
                PARTIALLY_SHIPPED: "Parzialmente spedito",
                OTHER: "Altro",
            }
        },
        ORDER:{
            CURRENT_ORDER_TITLE:"Il tuo ordine corrente",
            NO_ORDER1: "Il tuo ordine è vuoto. Dai un'occhiata al ",
            NO_ORDER2:  "e trova qualcosa che fa per te!",
            OUR_CATALOG:"nostro catalogo",
            ORDINE_NUMERO: "Ordine N°",
            DATA: "Data",
            QUANTITA_NELL_ORDINE: "Quantità nell'ordine",
            TOTALE: "Totale",
            SVUOTA: "Svuota",
            VAI_A_CHECKOUT: "Vai al checkout",
            VARIAZIONE_TEXT: "Articolo nell'ordine ha subito una variazione del prezzo a te dedicato. Puoi verificare le condizioni aggiornate nell'elenco qui sotto.",
            VARIAZIONI_TEXT: "Articoli nell'ordine hanno subito una variazione del prezzo a te dedicato. Puoi verificare le condizioni aggiornate nell'elenco qui sotto.",
            NO_ITEMS_IN_ORDER_CURRENT: (navigate: NavigateFunction) => <div>Il tuo ordine è vuoto. Dai un'occhiata al <span className="cs-link" onClick={(()=>navigate("/catalog"))}>nostro catalogo</span> e trova qualcosa che fa per te!</div>,
            NO_ITEMS_IN_ORDER: "L'ordine è vuoto.",
            ELENCO_ARTICOLI_ORDINE: "Elenco articoli nell'ordine",
            INFORMAZIONI_NON_DISPONIBILI: "Informazioni non disponibili.",
            TAGLIA: "Taglia",
            TAGLIE: "Taglie",
            STAGIONE: "Stagione",
            PREZZO_UNITARIO: "Prezzo unitario",
            QUANTITA: "Quantità",
            INDIRIZZO_DI_SPEDIZIONE: "Indirizzo di spedizione",
            NESSUN_INDIRIZZO_SPEDIZIONE: "Nessun indirizzo di spedizione indicato per quest'ordine.",
            SCEGLI_UN_INDIRIZZO: "Scegli un indirizzo",
            ORDINE_VERRA_SPEDITO_A: "Quest'ordine verrà spedito a",
            ORDINE_SPEDITO_A: "Quest'ordine è stato spedito a",
            PREZZO_CORRENTE_AGGIORNATO: "Prezzo corrente aggiornato",
            CONFERMA_RIMOZIONE_ARTICOLO: (sku: string)=> <p>Sei sicuro di voler rimuovere l'articolo <strong>{sku}</strong> dall'ordine?</p>,
            CONFERMA_SVUOTAMENTO_ORDINE: "Sei sicuro di voler svuotare l'ordine?",
            INDIRIZZO_ATTUALE: "Indirizzo attuale",
            NUOVO_INDIRIZZO: "Inserisci un nuovo indirizzo",
            SELEZIONA_UN_ALTRO_INDIRIZZO: "Seleziona un altro indirizzo",
            ACQUISTA_ALTRE_TAGLIE: "Acquista altre taglie",
        },
        ITEM: {
            MAGGIORI_INFORMAZIONI: "Maggiori informazioni",
            VARIAZIONI_DISPONIBILI: "Variazioni disponibili",
            NASCONDI_ESAURITE: "Nascondi esaurite",
            MORE_INFO: {
                BRAND: "Brand",
                COLOR: "Colore",
                COMPOSITION: "Composizione",
                DESCRIZIONE: "Descrizione",
                HS_CODE: "HS Code",
                SEASON: "Stagione",
                MADE_IN: "Made in",
                GENDER: "Gender",
                FIRST_CATEGORY: "First category",
                SECOND_CATEGORY: "Second category",
                SCALA_TAGLIE: "Scala taglie",
                TITOLO: "Titolo",
                DIMENSIONI: "Dimensioni",
                CARRY_OVER: "Carry Over",
            }
        }
    },
    ITEMS:{
        NO_ITEM_PRESENT: "Non ci sono articoli nell'ordine.",
        ITEM_NOT_PRESENT: "Articolo non presente nell'inventario.",
        ITEMS_ORDER:"Articoli in questo ordine",
        SKU:"Sku",
        BRAND:"Brand",
        SEASON:"Stagione",
        TITLE:"Titolo",
        QT_ROW:"Quantità riga ordine",
        TOT_ROW:"Costo totale di riga",
        SIZE:"Taglia",
        COLOR:"Colore",
        QTY:"Quantità",
        PRICE:"Prezzo",
    },
     FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Select at least one filter to view results.",
        NESSUN_RISULTATO: "No results found.",
        ERRORE: "An error occurred, please try again."
    },
}

export default it