import { CostCurrencyEnum, Order, OrderItem, ShopItem } from "@csapi/shop"
import { getItemsByIdList } from "@api/Items";
import { Item } from "@csapi/provisioning";
import { NotFoundItemInArray } from "../../../src/interfaces/Items";
import { OrderItemWithInfo } from "@interfaces/Orders";
import Localization from "@localization/Index";

enum OrderStatus {
    PENDING= "PENDING", 
    CONFIRMED= "CONFIRMED", 
    CANCELED= "CANCELED", 
    REFURBISHED= "REFURBISHED", 
    SHIPPED= "SHIPPED", 
    PARTIALLY_SHIPPED= "PARTIALLY_SHIPPED", 
    OTHER= "OTHER"
}

const orderStatuses = [
    { status: OrderStatus.PENDING, icon: "bi-pencil-square", color: "warning", label: Localization.PAGES.ORDERS.STATUS.PENDING},
    { status: OrderStatus.CONFIRMED, icon: "bi-check-square", color: "success", label: Localization.PAGES.ORDERS.STATUS.CONFIRMED},
    { status: OrderStatus.CANCELED, icon: "bi-dash-square", color: "danger", label: Localization.PAGES.ORDERS.STATUS.CANCELED},
    { status: OrderStatus.REFURBISHED, icon: "bi-arrow-repeat", color: "muted", label: Localization.PAGES.ORDERS.STATUS.REFURBISHED},
    { status: OrderStatus.SHIPPED, icon: "bi-truck", color: "primary", label: Localization.PAGES.ORDERS.STATUS.SHIPPED},
    { status: OrderStatus.PARTIALLY_SHIPPED, icon: "bi-truck-flatbed", color: "info", label: Localization.PAGES.ORDERS.STATUS.PARTIALLY_SHIPPED},
    { status: OrderStatus.OTHER, icon: "bi-question-circle", color: "dark", label: Localization.PAGES.ORDERS.STATUS.OTHER},
]

/**
 * Get all the order status rendering elements
 * @param status the order status
 */
const getOrderStatus = (status?:OrderStatus)=>{
    return orderStatuses.find(orderStatus=>orderStatus.status===status) ?? orderStatuses.find(orderStatus=>orderStatus.status===OrderStatus.OTHER)!
}

const getOrderItemsWithInfo = async (order:Order, lang:string)=>{
    try {
        const orderItemsIds = order.items?.map(item=>item.item_id?.$oid) as string[]
        const items = await getItemsByIdList(orderItemsIds)
        const orderItems:OrderItemWithInfo[] = items.map(item =>{
            const notFoundItem = item as NotFoundItemInArray
            if(notFoundItem.index!== undefined) {
                // manage return value of not found element
                const orderItemNotFoundOriginal = order.items?.[notFoundItem.index]
                const { item_id, price_adjustments, total_price_tax_incl, price, qty, unit_price_tax_incl  } = orderItemNotFoundOriginal!

                return {
                    id: item_id?.$oid ?? "",
                    sku: "",
                    img: "",
                    skuParent: "N.D.",
                    description: "N.D.",
                    brand: "N.D.",
                    variation: price_adjustments?.[0]?.reason_id,
                    variationType: price_adjustments?.[0]?.reason_title,
                    qty,
                    cost: {amount: price, currency: total_price_tax_incl?.currency ?? "EUR"},
                    unit_price: {amount: unit_price_tax_incl?.amount, currency: unit_price_tax_incl?.currency ?? "EUR"},
                }
            }

            const foundItem = item as Item

            const foundOrderItemOriginal = order.items?.find(i=>i.item_id?.$oid===foundItem.item_id?.$oid)
            const { price_adjustments, total_price_tax_incl, price, qty, unit_price_tax_incl  } = foundOrderItemOriginal!

            const title = foundItem?.locs?.singles?.title?.[lang] ?? (foundItem?.locs?.singles?.title?.["it"] ?? foundItem?.props?.['title'])

            return {
                id: foundItem.item_id?.$oid ?? "",
                sku: foundItem.sku,
                img: foundItem.imgs?.[0].url ?? "",
                skuParent: (foundItem.props?.parent_sku ?? "N.D.") as string,
                description: (title ?? "N.D.") as string,
                brand: (foundItem.props?.brand ?? "N.D.") as string,
                variation: price_adjustments?.[0]?.reason_id,
                variationType: price_adjustments?.[0]?.reason_title,
                qty,
                cost: {amount: price, currency: total_price_tax_incl?.currency ?? "EUR"},
                unit_price: {amount: unit_price_tax_incl?.amount, currency: unit_price_tax_incl?.currency ?? "EUR"},
                cat_ids: foundItem.cats,
                barcode: foundItem.barcodes?.[0].code ?? (foundItem.props?.barcode as unknown as string)
            }
        })

        return orderItems
    } catch(err) {
        console.log(err)
        throw err
    }
}

//TODO: add the head fields needed for the BeCommerce
export const generateEmptyOrder = ()=>{
    const order: Order = {
        order_status: "PENDING",
        tot_qty: 0,
        tot_amount: {
            amount: 0,
            currency: "EUR"
        },
        items: []
    }

    return order
}

export type OrderItemAdded = {
    quantity: number
    item: ShopItem
    customerPrice: number
}

export const removeItemFromOrder = (order: Order, shopItem: ShopItem, currency:CostCurrencyEnum="EUR") =>{
    if(order.items?.length === 0) {
        return order
    }

    const updatedOrder = structuredClone(order) as Order
    const items = updatedOrder.items ?? []
    updatedOrder.items = items.filter(item=>item.sku !== shopItem.sku)

    const summaryOrderUpdated = updateOrderSummary(updatedOrder, currency)
    return summaryOrderUpdated

}

export const emptyOrder = (order: Order, currency:CostCurrencyEnum="EUR") =>{
    if(order.items?.length === 0) {
        return order
    }

    const updatedOrder = structuredClone(order) as Order
    updatedOrder.items = []
    const summaryOrderUpdated = updateOrderSummary(updatedOrder, currency)
    return summaryOrderUpdated
}

export const addItemsToOrder = (order: Order, itemsAdded: OrderItemAdded[], tax_rate:number, priceType:"MARKUP"|"DISCOUNT", currency:CostCurrencyEnum="EUR") =>{
    if(itemsAdded.length === 0) {
        return order
    }
    const updatedOrder = structuredClone(order) as Order
    const items = updatedOrder.items ?? []

    itemsAdded.forEach(itemAdded=>{
        const { quantity, item, customerPrice } = itemAdded
        const { stock_price=0, props, } = item
        const itemIndex = items.findIndex(orderItem=>orderItem.sku === item.sku)
        const currentItemRow =  (itemIndex >=0)?items[itemIndex]: {}
    
        // common properties
        currentItemRow.sku = item.sku
        currentItemRow.attributes = {sku_parent: props?.sku_parent as object}
        const newQuantity = (currentItemRow.qty ?? 0) + quantity
        currentItemRow.price = customerPrice * newQuantity
        currentItemRow.qty = newQuantity
    
        // fields by MARKUP / DISCOUNT
        if(priceType === "DISCOUNT") {
            const unit_price_tax = stock_price - (stock_price / (1+tax_rate/100))
            currentItemRow.unit_price_tax_incl = {amount: stock_price, currency}
            currentItemRow.unit_price_tax = {amount: unit_price_tax, currency}
            currentItemRow.unit_price_tax_excl = {amount: stock_price - unit_price_tax, currency}
            currentItemRow.total_price_tax_incl = {amount: currentItemRow.unit_price_tax_incl?.amount! * newQuantity, currency}
            currentItemRow.total_price_tax = {amount: currentItemRow.total_price_tax?.amount! * newQuantity, currency}
            currentItemRow.total_price_tax_excl = {amount: currentItemRow.total_price_tax_excl?.amount! * newQuantity, currency}
            const adj_with_tax = currentItemRow.total_price_tax_incl.amount! - currentItemRow.price
            const adj_tax = adj_with_tax - (adj_with_tax / (1+tax_rate/100))
            currentItemRow.price_adjustments = [
                {
                    adj_with_tax: adj_with_tax,
                    adj_tax,
                    adj_no_tax: currentItemRow.total_price_tax_incl.amount! - adj_tax,
                    reason_id: ((adj_with_tax / currentItemRow.total_price_tax_incl?.amount!) * 100).toFixed(0),
                    reason_title: "DISCOUNT"
                }
            ]
            
        } else {
            const buy_price = (props?.buy_price ?? 0) as number
            currentItemRow.unit_price_tax_incl = {amount: buy_price, currency}
            currentItemRow.unit_price_tax = {amount: 0, currency}
            currentItemRow.unit_price_tax_excl = {amount: buy_price, currency}
            currentItemRow.total_price_tax_incl = {amount: buy_price*newQuantity, currency}
            currentItemRow.total_price_tax = {amount: 0, currency}
            currentItemRow.total_price_tax_excl = {amount: buy_price*newQuantity, currency}
            currentItemRow.price_adjustments = [
                {
                    adj_with_tax: -(currentItemRow.total_price_tax_incl?.amount! - currentItemRow.price),
                    adj_tax: 0,
                    adj_no_tax: -(currentItemRow.total_price_tax_incl?.amount! - currentItemRow.price),
                    reason_id: (((-(currentItemRow.total_price_tax_incl?.amount! - currentItemRow.price)) / currentItemRow.total_price_tax_incl?.amount!)*100).toFixed(0),
                    reason_title: "MARKUP"
                }
            ]
        }
    
        if(itemIndex<=0) {
            items.push(currentItemRow)
        } else{
            items[itemIndex] = currentItemRow
        }
    })
    

    updatedOrder.items = items
    
    const summaryOrderUpdated = updateOrderSummary(updatedOrder, currency)
    return summaryOrderUpdated
}

export const updateOrderSummary = (order: Order, currency: CostCurrencyEnum) =>{
    const { items=[] } = order

    //TODO: recalculate all derived header properties
    const totalQuantity = items.reduce((prev:number, curr:OrderItem)=>{
        return prev + (curr.qty ?? 0)
    },0)

    const totalAmount = items.reduce((prev:number, curr:OrderItem)=>{
        return prev + (curr.price ?? 0)
    },0)

    order.tot_qty = totalQuantity
    order.tot_amount = {amount: totalAmount, currency}

    return order
}

export { OrderStatus, getOrderStatus, orderStatuses, getOrderItemsWithInfo }
